import classNames from 'classnames';
import { SvgArrow } from '@/components/svg';
import useToggle from '@/hooks/useToggle';

const ProgramWorkoutSessions = ({
  sessions,
}: {
  sessions: { title: string; duration: string }[];
}) => {
  const [isOpen, toggleOpen] = useToggle();

  return (
    <div className="mt-4 max-w-full sm:max-w-[310px]">
      <div
        className="flex cursor-pointer items-center justify-between text-base font-normal"
        onClick={toggleOpen}
      >
        <span>{sessions.length - 1} Sessions Available</span>
        <span>
          <SvgArrow
            className={classNames(
              'h-6 w-6 transition-all duration-500',
              isOpen ? 'rotate-180' : 'rotate-0'
            )}
          />
        </span>
      </div>
      {isOpen && (
        <div className="mt-2 border-t-2 border-landing-redMedium py-3">
          {sessions.map((item, index) => (
            <p
              key={`item_${index}_${item.title}`}
              className="mb-3 flex justify-between text-base font-normal text-white/80 last:mb-0"
            >
              <span>{index + 1}. </span>
              <span className="w-full">{item.title}</span>
              <span className="ml-4">{item.duration}</span>
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProgramWorkoutSessions;
