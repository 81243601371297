import classNames from 'classnames';

interface LandingHeaderProps {
  children: React.ReactNode;
  className?: string;
}

const LandingHeader = ({ children, className }: LandingHeaderProps) => (
  <h3
    className={classNames(
      'font-stag text-[32px] font-bold leading-[50px] sm:text-[46px]',
      className
    )}
  >
    {children}
  </h3>
);

export default LandingHeader;
