import { Dispatch, SetStateAction } from 'react';
import classNames from 'classnames';

import { LandingImageWithIcon } from '@/components/Landing';

interface BonusSessionWrapperProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  setVideoUrl: Dispatch<SetStateAction<string | null>>;
  url: string;
  image: any;
  wrapperClasses?: string;
  borderClasses: string;
  imageClasses: string;
}

const BonusSessionWrapper = ({
  setOpen,
  setVideoUrl,
  url,
  image,
  wrapperClasses,
  borderClasses,
  imageClasses,
}: BonusSessionWrapperProps) => {
  const handleClick = () => {
    setOpen(true);
    setVideoUrl(url);
  };

  return (
    <div className={classNames('relative', wrapperClasses)}>
      <div
        className={classNames(
          'absolute bottom-0 left-0 mt-8 h-[90%] w-[75%] border',
          borderClasses
        )}
      />
      <div className={classNames('relative aspect-video', imageClasses)}>
        <LandingImageWithIcon image={image} handleClick={handleClick} />
      </div>
    </div>
  );
};

export default BonusSessionWrapper;
