import Image from 'next/image';
import { LANDING } from '@/constants/index';
import RushfitImage from '@/assets/landing/Rushfit_Circle.png';
import RushfitTwoImage from '@/assets/landing/Rushfit2_Circle.png';
import StrikeImage from '@/assets/landing/Strike_Circle.png';
import BonusSessionsImage from '@/assets/landing/Bonus_Circle.png';

const programmes = [
  {
    name: (
      <span>
        Rushfit <sup>TM</sup> 2
      </span>
    ),
    href: LANDING.SECTION_IDS.RUSHFIT_TWO,
    image: RushfitTwoImage,
  },
  {
    name: (
      <span>
        Rushfit <sup>TM</sup>{' '}
      </span>
    ),
    href: LANDING.SECTION_IDS.RUSHFIT,
    image: RushfitImage,
  },
  {
    name: 'Strike',
    href: LANDING.SECTION_IDS.STRIKE,
    image: StrikeImage,
  },
  {
    name: 'Bonus Sessions',
    href: LANDING.SECTION_IDS.BONUS_SESSION,
    image: BonusSessionsImage,
  },
];

const ProgrammesHeading = () => (
  <section
    id={LANDING.SECTION_IDS.ALL_PROGRAMS}
    className="flex flex-col justify-center bg-landing-dark p-6 py-20 sm:p-20 lg:p-[120px] "
  >
    <div className="grid grid-cols-2 items-center justify-center gap-10 md:gap-20 lg:grid-cols-4">
      {programmes.map((item, index) => (
        <div
          key={`item_${index}_${item.name}`}
          className="flex flex-col items-center justify-center text-center"
        >
          <a href={`#${item.href}`} className="mb-6">
            <div className="relative flex h-[144px] w-[144px] cursor-pointer justify-center overflow-hidden rounded-full sm:h-[194px] sm:w-[194px]">
              <Image
                src={item.image}
                fill
                alt=""
                className="h-[144px] w-[144px] object-cover sm:h-[194px] sm:w-[194px]"
              />
            </div>
          </a>
          <a href={`#${item.href}`}>
            <p className="whitespace-nowrap font-stag text-base font-bold leading-4 sm:text-xl sm:leading-6">
              {item.name}
            </p>
          </a>
        </div>
      ))}
    </div>
  </section>
);

export default ProgrammesHeading;
