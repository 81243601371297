import Image from 'next/image';
import Link from 'next/link';

import HeroBanner from '@/assets/landing/program-details-background.png';
import { Button } from '@/components/shared/Buttons';
import { LandingParagraph } from '@/components/Landing';

import { PAGE } from '@/router/routes';
import { ILandingProgram, LANDING } from '@/constants/index';

const DetailsBanner = ({ program }: { program: ILandingProgram }) => (
  <section className="relative min-h-screen">
    <Image src={HeroBanner} fill className="object-cover" alt="" />

    <div className="relative flex h-full min-h-screen w-full flex-col items-center justify-center px-6 pb-20 pt-32 text-center sm:px-20 sm:py-14 lg:px-[120px]">
      <div className="flex w-full flex-col justify-center gap-8 md:max-w-[767px]">
        <div className="mt-10 flex items-center justify-center font-stag text-4xl font-semibold leading-[36px] sm:mt-16 sm:text-[58px] sm:leading-[58px]">
          {program.title}
        </div>
        <div>
          <LandingParagraph className="mb-2">{program.description}</LandingParagraph>
        </div>
        <div className="flex justify-center">
          <Link href={PAGE.OPTIONS_SIGNUP} passHref>
            <div>
              <Button>Get Started</Button>
            </div>
          </Link>
        </div>
      </div>
      <div className="mt-12 flex w-full flex-col gap-4 sm:mt-16 sm:flex-row sm:justify-center sm:gap-12">
        <div className="relative aspect-[314/231] w-full overflow-hidden rounded-md sm:max-w-[314px]">
          <Image src={program.images[0]} fill alt="" className="rounded-md object-cover" />
        </div>
        <div className="relative aspect-[407/231] w-full overflow-hidden rounded-md sm:max-w-[407px]">
          <Image src={program.images[1]} fill alt="" className="rounded-md object-cover" />
        </div>
        <div className="relative aspect-[314/231] w-full overflow-hidden rounded-md sm:max-w-[314px]">
          <Image src={program.images[2]} fill alt="" className="rounded-md object-cover" />
        </div>
      </div>
    </div>
  </section>
);

export default DetailsBanner;
