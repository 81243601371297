import Image from 'next/image';
import Link from 'next/link';
import { Link as ScrollLink } from 'react-scroll';

// import HeroBanner from '@/assets/landing/hero-landing.png';
import HeroBanner from '@/assets/landing/hero-discount.png';
import { LANDING } from '@/constants/landing.const';
import { PAGE } from '@/router/routes';

import { Button } from '@/components/shared/Buttons';
import { LandingParagraph } from '@/components/Landing';
import { SvgCircleArrowDown } from '@/components/svg';

const HeroSection = () => (
  <section className="relative sm:min-h-screen">
    <div className="hidden sm:block">
      <Image src={HeroBanner} fill className="object-cover" alt="" />
    </div>
    <div className="landing-hero-gradient relative flex w-full flex-col justify-end px-6 pb-0 pt-32 sm:min-h-screen sm:justify-center sm:px-20 sm:py-14 sm:pt-32 lg:px-[120px]">
      <div className="flex w-full flex-col items-start justify-center gap-4 md:max-w-[485px]">
        <div className="mt-10 mb-6 font-stag text-4xl font-bold leading-[36px] sm:mt-0 sm:mb-10 sm:text-5xl">
          <p>50% OFF SELECTED</p>
          <p>PRODUCTS</p>
        </div>

        <div className="font-stag text-4xl font-bold leading-[36px] sm:mt-0 sm:text-5xl">
          Get Fit Fighting with
          <p className="text-brand-primary">Georges St-Pierre</p>
        </div>
        <div className="flex max-w-[395px] flex-col gap-4 sm:gap-6">
          <LandingParagraph className="mb-2">
            UFC World Champion, Georges and his trainers bring you the best fitness and technical
            martial arts programs to challenge your body & mind.
          </LandingParagraph>
          <LandingParagraph className="mb-8">
            Whether you fight or not, this is a sure-fire route to GET FIT and learn MMA with GSP.
          </LandingParagraph>
        </div>
        <div className="flex w-full justify-center sm:justify-start">
          <Link href={PAGE.OPTIONS_SIGNUP} passHref>
            <Button size="large" className="h-[55px] border border-brand-primaryHover">
              Get Started
            </Button>
          </Link>
        </div>
        <div className="absolute inset-x-0 bottom-6 hidden justify-center sm:flex">
          <a href={`#${LANDING.SECTION_IDS.ALL_PROGRAMS}`}>
            <div className="flex cursor-pointer gap-2 rounded-[60px] bg-[#087E8B]/70 px-5 py-4 font-stag text-base font-semibold">
              <SvgCircleArrowDown />
              Scroll down to discover more!
            </div>
          </a>
        </div>
      </div>
      <div className="relative -mr-6 mt-5 block h-[204px] sm:hidden">
        <Image src={HeroBanner} fill className="object-cover" alt="" />
        <div className="landing-hero-gradient absolute inset-0 z-10 h-full w-full" />
      </div>
    </div>
  </section>
);

export default HeroSection;
