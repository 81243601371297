import * as yup from 'yup';

export const detailsValidationSchema = yup.object().shape({
  username: yup
    .string()
    .required('Please enter username.')
    .min(4, 'Username is too short.')
    .max(30, 'Username is too long.'),

  full_name: yup
    .string()
    // .required('Please enter full name.')
    // .min(4, 'Name is too short.')
    .max(70, 'Name is too long.'),

  bio: yup
    .string()
    // .required('Please enter bio.')
    .max(150, 'Bio should be less than 150 characters.'),

  // age: yup
  // .number()
  // .typeError('Amount must be a number')
  // .required('Please enter your age')
  // .positive()
  // .integer(),

  // height: yup
  //   .number()
  // .typeError('Height must be a number')
  // .required('Please enter your height')
  // .positive()
  // .integer(),

  // weight: yup
  //   .number()
  // .typeError('Weight must be a number')
  // .required('Please enter your weight')
  // .positive()
  // .integer(),

  gender: yup.string(),
});

export const passwordUpdateValidationSchema = yup.object().shape({
  oldPassword: yup.string().required('Please enter password.').min(8, 'Password is too short.'),
  newPassword: yup.string().required('Please enter password.').min(8, 'Password is too short.'),
  // .matches(
  //   /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
  //   'Password must contain at least 8 characters, one uppercase, one lowercase and one number'
  // )
  confirmPassword: yup
    .string()
    .required('Please enter password.')
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});

export const signInSchema = yup.object().shape({
  password: yup
    .string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required'),
  email: yup.string().email('Email is not valid').required('Email is required'),
});

export const signUpSchema = yup.object().shape({
  password: yup
    .string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required'),
  email: yup.string().email('Email is not valid').required('Email is required'),
  // username: yup.string().required('Username is required'),
  email_marketing: yup.bool(),
});

export const emailSchema = yup.object().shape({
  email: yup.string().email('Email is not valid').required('Email is required'),
});

export const newPasswordSchema = yup.object().shape({
  password: yup.string().required('Please enter password.').min(8, 'Password is too short.'),
  confirmPassword: yup
    .string()
    .required('Please enter password.')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

export const checkoutSchema = yup.object().shape({
  // cardNumber: yup.string(),
  // cardCvc: yup.string(),
  // cardExpiry: yup.string(),
});

export const contactFormValidationSchema = yup.object().shape({
  full_name: yup.string().required('Please enter full name.'),

  email: yup.string().email('Email is not valid').required('Email is required'),

  subject: yup.string().required('Please enter subject.'),

  message: yup
    .string()
    .required('Please enter message.')
    .max(500, 'Message should be less than 500 characters.'),
});
