import { useMemo } from 'react';
import classNames from 'classnames';
import { useController, useFormContext, UseFormReturn } from 'react-hook-form';

interface InputProps {
  id: string;
  name: string;
  type: string;
  placeholder?: string;
  autoComplete?: string;
  required?: boolean;
  defaultValue?: string | number;
  rules?: Object;
  label?: string;
  disabled?: boolean;
  className?: string;
  wrapperClassName?: string;
}

const Input = ({
  id,
  name,
  type,
  placeholder,
  autoComplete,
  defaultValue,
  required,
  rules,
  label,
  disabled,
  className,
  wrapperClassName = '',
}: InputProps) => {
  const form: UseFormReturn = useFormContext();

  const {
    control,
    formState: { errors },
  } = form;

  const { field } = useController({ control, name, rules, defaultValue });

  const error = errors[name];

  const inputClasses =
    'h-12 w-full p-3 text-base text-landing-dark bg-neutrals-white outline-none border rounded-md transition-all duration-300 ring-none placeholder:text-landing-dark/50 focus:outline-none focus:ring-0';

  const inputBorderColors = useMemo(
    () =>
      error
        ? 'focus:border-brand-primary border-brand-primary'
        : 'focus:border-neutrals-white border-neutrals-mid',
    // eslint-disable-next-line
    [!!error]
  );

  return (
    <div className={classNames('mb-4 w-full', disabled && 'opacity-50', wrapperClassName)}>
      {label && (
        <label htmlFor={id} className="mb-1 text-sm font-normal text-neutrals-white">
          {label}
        </label>
      )}
      <div className="relative">
        <input
          className={classNames(`${inputBorderColors} ${inputClasses} ${className}`)}
          id={id}
          type={type}
          placeholder={placeholder}
          autoComplete={autoComplete}
          required={required}
          disabled={disabled}
          {...field}
        />
      </div>
      {errors[name] && (
        <div className="mt-2 text-sm font-extrabold text-status-error">
          {errors[name]?.message?.toString()}
        </div>
      )}
    </div>
  );
};

export default Input;
