import Image from 'next/image';

import ImageBanner from '@/assets/landing/program-info-background.png';
import { SvgNumberOne, SvgNumberTwo, SvgNumberThree } from '@/components/svg';
import { LandingParagraph, ProgramWorkoutSessions } from '@/components/Landing';
import { ILandingProgram } from '@/constants/index';

const ProgramInfo = ({ program }: { program: ILandingProgram }) => {
  const svgIcon = (index: number) => {
    switch (index) {
      case 0:
        return <SvgNumberOne />;

      case 1:
        return <SvgNumberTwo />;

      case 2:
        return <SvgNumberThree />;

      default:
        return <SvgNumberOne />;
    }
  };

  return (
    <section className="program-details-info relative">
      <Image src={ImageBanner} fill className="object-cover object-top" alt="" />

      <div className="relative flex h-full w-full flex-col items-center justify-center px-6 py-20 text-center sm:py-[120px] sm:px-20 lg:px-[120px]">
        <h3 className="mb-14 font-stag text-[32px] font-bold leading-[32px] sm:text-[46px] sm:leading-[46px]">
          Program info
        </h3>
        <div className="w-full max-w-[824px]">
          {program?.info?.map((item, index) => (
            <div
              className="mb-4 flex flex-col items-start gap-0 sm:flex-row sm:gap-16"
              key={`item_${index}_${item.title}`}
            >
              <div className="relative flex h-[144px] w-[148px] items-center">{svgIcon(index)}</div>
              <div className="w-full text-left">
                <p className="mb-4 font-stag text-lg font-bold uppercase text-[#CD261F]">
                  {item.title}
                </p>
                <LandingParagraph className="!text-base">{item.text}</LandingParagraph>
                {item.sessions && <ProgramWorkoutSessions sessions={item.sessions} />}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ProgramInfo;
