import { useState } from 'react';
import { useForm, SubmitHandler, FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';
import { DotPulse } from '@uiball/loaders';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';

import Fetch from '@/services/fetch';
import { ToasterType } from '@/types/components/toaster.type';
import { LANDING } from '@/constants/landing.const';
import { notificationToaster } from '@/utils/index';
import { emailSchema } from '@/validation';

import { Input } from '@/components/shared/Inputs';
import { Button } from '@/components/shared/Buttons';
import { useNewsLetterSignUpGA } from '@/hooks/analitics/useGoogleAnalitics';

export const JoinTheRush = ({ withTopPadding }: { withTopPadding?: boolean }) => {
  const [loading, setLoading] = useState(false);

  const form: UseFormReturn = useForm({
    resolver: yupResolver(emailSchema),
  });
  const { handleSubmit, reset } = form;

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    setLoading(true);

    try {
      await Fetch.subscribeToNews(data.email);
      setLoading(false);
      reset();
      notificationToaster(ToasterType.SUCCESS, 'Thank you for joining Rushfit community');
      useNewsLetterSignUpGA();
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div id={LANDING.SECTION_IDS.JOIN_THE_RUSH} className="bg-landing-dark sm:my-0 sm:py-24">
          <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div
              className={classNames(
                'relative isolate overflow-hidden bg-black px-6 pb-16 shadow-2xl sm:rounded-3xl sm:py-16 sm:px-24 xl:py-32',
                withTopPadding && 'pt-16'
              )}
            >
              <h2 className="mx-auto max-w-2xl text-center font-stag text-3xl font-semibold tracking-tight text-white sm:text-4xl">
                Join the Rush!
              </h2>
              <p className="mx-auto mt-6 max-w-xl text-center text-lg leading-8 text-white">
                Sign up now for exclusive news and updates!
              </p>

              <div className="mx-auto mt-10 flex max-w-md flex-col gap-x-4 sm:flex-row">
                <Input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter your email"
                  defaultValue=""
                />

                <Button type="submit" size="large" className="w-full shrink-0 sm:w-[156px]">
                  {loading ? <DotPulse size={20} speed={1.3} color="white" /> : 'Join the Rush'}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
