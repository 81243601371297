import Image from 'next/image';
import { Dispatch, SetStateAction } from 'react';

import { LandingParagraph, LandingImageWithIcon } from '@/components/Landing';
import { SvgPlayIcon } from '@/components/svg';
import VideoImage from '@/assets/landing/landing-video-section.jpeg';
import { LANDING } from '@/constants/index';

interface LandingVideoSectionProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  setVideoUrl: Dispatch<SetStateAction<string | null>>;
}

const LandingVideoSection = ({ setOpen, setVideoUrl }: LandingVideoSectionProps) => {
  const handleClick = () => {
    setOpen(true);
    setVideoUrl(LANDING.VIDEO_URLS.GENERAL);
  };

  return (
    <section className="flex flex-col items-center gap-8 px-6 py-20 md:flex-row md:gap-16 md:px-20 lg:gap-20 lg:px-[120px]">
      <div className="w-full border-t-[3px] border-brand-primary pt-10 md:w-[55%]">
        <LandingParagraph className="mb-6">
          “There is a difference between a fighter and a martial artist. A fighter is training for a
          purpose. He has a fight. I'm a martial artist. I don't train for a fight; I train for
          myself. My goal is perfection, but I will never reach perfection. Never stop reaching your
          goal.”
        </LandingParagraph>
        <p className="text-[22px] font-extrabold leading-6">Georges St-Pierre</p>
      </div>
      <div className="relative aspect-video w-full overflow-hidden rounded md:w-[45%]">
        <LandingImageWithIcon image={VideoImage} handleClick={handleClick} />
      </div>
    </section>
  );
};

export default LandingVideoSection;
