import ReactGA from 'react-ga4';

export const useAccountRegistrationGA = (data: { id: number }) => {
  ReactGA.initialize(`${process.env.NEXT_PUBLIC_GA_TOKEN}`);
  ReactGA.gtag('event', 'account_registration', {
    account_id: ` ${data?.id}`,
  });
};
export const useVideoClickGA = (data: {
  video_pack: string;
  video_name: string;
  video_page_location: string;
}) => {
  ReactGA.initialize(`${process.env.NEXT_PUBLIC_GA_TOKEN}`);
  ReactGA.gtag('event', 'video_click', {
    video_pack: data.video_pack,
    video_name: data.video_name,
    video_page_location: data.video_page_location,
  });
};

export const useNewsLetterSignUpGA = () => {
  ReactGA.initialize(`${process.env.NEXT_PUBLIC_GA_TOKEN}`);
  ReactGA.gtag('event', 'newsletter_signup');
};

export const useViewItemListGA = (view_item: { item_name: string; item_id: number }[]) => {
  ReactGA.initialize(`${process.env.NEXT_PUBLIC_GA_TOKEN}`);
  ReactGA.gtag('event', 'view_item_list', {
    ecommerce: { items: view_item },
  });
};

export const useAddToCardGA = (
  item: { item_name: string; item_id: number; price: number },
  currency: string,
  value: number
) => {
  ReactGA.initialize(`${process.env.NEXT_PUBLIC_GA_TOKEN}`);
  ReactGA.gtag('event', 'add_to_cart', {
    currency: currency,
    value: value,
    ecommerce: item,
  });
};
export const useRemoveFromCardGA = (
  item: { item_name: string; item_id: number; price: number },
  currency: string,
  value: number
) => {
  ReactGA.initialize(`${process.env.NEXT_PUBLIC_GA_TOKEN}`);
  ReactGA.gtag('event', 'remove_from_cart', {
    ecommerce: { currency: currency, value: value, items: item },
  });
};

export const usePurchaseGA = (
  item: { item_name: string; item_id: number; price: number }[] | undefined,
  transaction_id: string,
  currency: string | any,
  value: number | undefined
) => {
  ReactGA.initialize(`${process.env.NEXT_PUBLIC_GA_TOKEN}`);
  ReactGA.gtag('event', 'purchase', {
    ecommerce: {
      transaction_id: transaction_id,
      currency: currency,
      value: value,
      items: item,
    },
  });
};

export const useLoginGA = (id: string) => {
  // @ts-ignore
  window.dataLayer = window.dataLayer || [];
  // @ts-ignore
  window.dataLayer.push({
    is_logged_in: true,
    account_id: id,
  });
};
