import { Dispatch, SetStateAction } from 'react';

import StrikeImage from '@/assets/landing/Strike_Cover.png';
import { LANDING } from '@/constants/index';
import { LandingImageWithIcon } from '@/components/Landing';

interface ProgramRushfitProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  setVideoUrl: Dispatch<SetStateAction<string | null>>;
}

const ProgramStrike = ({ setOpen, setVideoUrl }: ProgramRushfitProps) => {
  const handleClick = () => {
    setOpen(true);
    setVideoUrl(LANDING.VIDEO_URLS.STRIKE);
  };

  return (
    <div className="relative py-3 pr-4 pl-0 sm:p-6 sm:px-6">
      <div className="absolute bottom-0 right-0 h-full w-[60%] border" />
      <div className="relative aspect-video overflow-hidden rounded-md">
        <LandingImageWithIcon image={StrikeImage} handleClick={handleClick} />
      </div>
    </div>
  );
};

export default ProgramStrike;
