import classNames from 'classnames';

interface LandingParagraphProps {
  children: React.ReactNode;
  className?: string;
}

const LandingParagraph = ({ children, className }: LandingParagraphProps) => (
  <p className={classNames('text-base leading-5 sm:text-lg sm:leading-6', className)}>{children}</p>
);

export default LandingParagraph;
