import { Dispatch, SetStateAction } from 'react';

import RushfitTwoImage from '@/assets/landing/Rushfit2_Cover.png';
import { LANDING } from '@/constants/index';
import { LandingImageWithIcon } from '@/components/Landing';

interface ProgramRushfitProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  setVideoUrl: Dispatch<SetStateAction<string | null>>;
}

const ProgramRushfitTwo = ({ setOpen, setVideoUrl }: ProgramRushfitProps) => {
  const handleClick = () => {
    setOpen(true);
    setVideoUrl(LANDING.VIDEO_URLS.RUSHFIT_TWO);
  };

  return (
    <div className="relative pb-1">
      <div className="absolute bottom-0 left-0 mt-8 h-[90%] w-[75%] border" />
      <div className="relative ml-3 mb-3 aspect-video overflow-hidden rounded-md sm:ml-6 sm:mb-6">
        <LandingImageWithIcon image={RushfitTwoImage} handleClick={handleClick} />
      </div>
    </div>
  );
};

export default ProgramRushfitTwo;
