import { Dispatch, SetStateAction } from 'react';
import Link from 'next/link';

import SessionOneImage from '@/assets/landing/Bonus_SelfDefense.png';
import SessionTwoImage from '@/assets/landing/Bonus_Grappling.png';
import SessionThreeImage from '@/assets/landing/Bonus_Core.png';
import SessionFourImage from '@/assets/landing/Bonus_PartnerDrills.png';
import { PAGE } from '@/router/routes';

import { LANDING } from '@/constants/index';
import { LandingHeader, LandingParagraph, BonusSessionWrapper } from '@/components/Landing';
import { Button } from '@/components/shared/Buttons';

interface LandingSessionsProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  setVideoUrl: Dispatch<SetStateAction<string | null>>;
}

const LandingSessions = ({ setOpen, setVideoUrl }: LandingSessionsProps) => {
  const sessions = [
    {
      title: 'Bonus 1: Self Defense',
      duration: '11 min',
      text: [
        "It's a sad fact of life in the 21st century that knowing how to physically protect yourself and loved ones is a useful skill to possess. This session will teach you how to adapt many octagon techniques to a street situation, ensuring you can handle danger and live to fight another day.",
      ],
      videoComponent: (
        <BonusSessionWrapper
          setOpen={setOpen}
          setVideoUrl={setVideoUrl}
          url={LANDING.BONUS_SESSIONS.BONUS_ONE}
          image={SessionOneImage}
          wrapperClasses="pb-1"
          borderClasses="bottom-0 left-0 mt-8 h-[90%] w-[75%]"
          imageClasses="ml-6 mb-6"
        />
      ),
      price: <span>$6.99</span>,
    },

    {
      title: 'Bonus 2: Grappling',
      duration: '16 min',
      text: [
        'To become a complete fighter, you need a solid set of ground skills, but grappling is complex and so can be difficult to learn as you need to use your whole body and often your limbs are pushing, pulling, and twisting, all at the same time. This unique session teaches escapes and submissions and then creatively links them together in flowing drills that are fun to practice and highly effective.',
      ],
      videoComponent: (
        <BonusSessionWrapper
          setOpen={setOpen}
          setVideoUrl={setVideoUrl}
          url={LANDING.BONUS_SESSIONS.BONUS_TWO}
          image={SessionTwoImage}
          borderClasses="bottom-0 right-0 h-[60%] w-full"
          wrapperClasses="pb-6"
          imageClasses="mx-6"
        />
      ),
      price: <span>$11.99</span>,
    },

    {
      title: 'Bonus 3: Core Strength',
      duration: '22 min',
      text: [
        'Powerful punches and kicks are not generated by the arms and legs alone, the key components are the deeper muscles in the torso that stabilize the spine, pelvis and shoulder girdle, enabling you to transfer power into your upper and lower limbs. A developed core enables you to rotate hips and shoulders at speed when attacking and also gives you anti-rotational strength that is important for defending yourself on the floor.',
      ],
      videoComponent: (
        <BonusSessionWrapper
          setOpen={setOpen}
          setVideoUrl={setVideoUrl}
          url={LANDING.BONUS_SESSIONS.BONUS_THREE}
          image={SessionThreeImage}
          wrapperClasses="pb-1"
          borderClasses="bottom-0 left-0 mt-8 h-[90%] w-[75%]"
          imageClasses="ml-6 mb-6"
        />
      ),
      price: <span>$11.99</span>,
    },

    {
      title: 'Bonus 4: Partner Drills',
      duration: '19 min',
      text: [
        "As the saying goes, drills build skills and that's the guarantee here as you work through a range of techniques and Dutch style combinations with a partner to add realism to your workout. This is a great way to master offence and defense while simultaneously improving control, accuracy, and fluidity.",
      ],
      videoComponent: (
        <BonusSessionWrapper
          setOpen={setOpen}
          setVideoUrl={setVideoUrl}
          url={LANDING.BONUS_SESSIONS.BONUS_FOUR}
          image={SessionFourImage}
          wrapperClasses="pb-6"
          borderClasses="bottom-0 right-0 h-[60%] w-full"
          imageClasses="mx-6"
        />
      ),
      price: <span>$11.99</span>,
    },
  ];

  return (
    <div className="bg-landing-dark">
      {sessions.map((item, i) => (
        <section
          key={`item_${item.title}_${i}`}
          className="flex flex-col items-center gap-12 py-20 px-6 pb-0 last:pb-20 md:gap-20 md:px-20 md:py-[120px] lg:flex-row lg:px-[120px] lg:odd:flex-row-reverse"
        >
          <div className="w-full shrink">
            <LandingHeader className="mb-1">{item.title}</LandingHeader>
            <p className="mb-4 font-stag text-lg font-semibold text-white/80">
              Duration: {item.duration}
            </p>

            {item.text.map((paragraph, index) => (
              <LandingParagraph key={`item_${paragraph}_${index}`} className="mb-2">
                {paragraph}
              </LandingParagraph>
            ))}
            <p className="my-8 flex items-center text-center font-stag text-[22px] font-bold lg:text-left">
              {item.price}
            </p>
            <div className="mt-10 flex justify-center lg:justify-start">
              <Link href={PAGE.OPTIONS_SIGNUP} passHref>
                <div className="w-min">
                  <Button>Get Started</Button>
                </div>
              </Link>
            </div>
          </div>
          <div className="w-full max-w-full sm:max-w-[484px] lg:max-w-[643px]">
            {item.videoComponent}
          </div>
        </section>
      ))}
    </div>
  );
};

export default LandingSessions;
