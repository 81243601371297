import { Fragment } from 'react';
import { useRouter } from 'next/router';
import Image, { StaticImageData } from 'next/image';

import { SvgPlayIcon } from '@/components/svg';
import { PAGE } from '@/router/routes';

interface LandingImageWithIconProps {
  image: StaticImageData;
  handleClick: () => void;
}

const LandingImageWithIcon = ({ image, handleClick }: LandingImageWithIconProps) => {
  const { pathname } = useRouter();

  return (
    <Fragment>
      <Image src={image} fill alt="" className="rounded-md object-cover" />
      <div className="landing-image-gradient absolute top-0 left-0 flex h-full w-full items-center justify-center">
        <span className="cursor-pointer">
          <SvgPlayIcon onClick={handleClick} />
        </span>
        {pathname === PAGE.PROGRAM_DETAILS && (
          <span className="absolute top-3 left-3 rounded-lg bg-brand-primary/70 p-2 font-stag text-sm font-semibold uppercase">
            preview
          </span>
        )}
      </div>
    </Fragment>
  );
};

export default LandingImageWithIcon;
