import { Dispatch, SetStateAction } from 'react';

import RushfitImage from '@/assets/landing/Rushfit_Cover.png';
import { LANDING } from '@/constants/index';
import { LandingImageWithIcon } from '@/components/Landing';

interface ProgramRushfitProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  setVideoUrl: Dispatch<SetStateAction<string | null>>;
}

const ProgramRushfit = ({ setOpen, setVideoUrl }: ProgramRushfitProps) => {
  const handleClick = () => {
    setOpen(true);
    setVideoUrl(LANDING.VIDEO_URLS.RUSHFIT);
  };

  return (
    <div className="relative pb-3 sm:pb-6">
      <div className="absolute bottom-0 right-0 h-[60%] w-full border" />
      <div className="relative mx-3 aspect-video overflow-hidden rounded-md sm:mx-6">
        <LandingImageWithIcon image={RushfitImage} handleClick={handleClick} />
      </div>
    </div>
  );
};

export default ProgramRushfit;
