import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';

interface PaymentInputProps {
  name: string;
  className?: string;
  showErrorOnFail?: boolean;
  placeholder?: string;
  type?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const PaymentInput = ({
  className = '',
  name,
  showErrorOnFail = true,
  ...rest
}: PaymentInputProps) => {
  const methods = useFormContext();

  return (
    <div className="flex flex-1 flex-col">
      <input
        {...methods.register(name)}
        className={classNames(
          'min-h-[45px] flex-1 rounded border-none bg-white px-5 text-landing-dark outline-none placeholder:text-base placeholder:text-landing-dark/50 focus:outline-none focus:ring-0',
          className
        )}
        {...rest}
      />
      {showErrorOnFail && methods.formState?.errors?.[name] && methods?.formState?.isSubmitted && (
        <span className="mt-1 text-sm font-extrabold text-status-error">
          {methods.formState.errors?.[name]?.message as string}
        </span>
      )}
    </div>
  );
};

export default PaymentInput;
