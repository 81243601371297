import classNames from 'classnames';
import { SvgCheckboxChecked } from '@/components/svg';

const PaymentCheckbox = ({ onToggle, checked }: { onToggle: () => void; checked: boolean }) => {
  const checkboxClasses = classNames(
    'min-w-[28px] min-h-[28px] border-white border rounded ease-in duration-200 flex justify-center items-center outline-none',
    { 'bg-brand-primary border-brand-primary': checked }
  );

  return (
    <button type="button" className={checkboxClasses} onClick={onToggle}>
      {checked && <SvgCheckboxChecked />}
    </button>
  );
};

export default PaymentCheckbox;
