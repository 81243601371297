import Image from 'next/image';
import Link from 'next/link';

import { PAGE } from '@/router/routes';
import { ILandingProgram, LANDING } from '@/constants/index';
import { LandingParagraph } from '@/components/Landing';
import { Button } from '@/components/shared/Buttons';

const LandingHowItWorks = ({ program }: { program: ILandingProgram }) => (
  <section className="flex flex-col items-center justify-center gap-10 bg-landing-dark px-6 py-20 sm:px-20 lg:py-[120px] lg:px-[120px]">
    <div className="relative max-w-[871px] text-center">
      <h3 className="mb-4 font-stag text-[32px] font-bold leading-[32px] sm:text-[46px] sm:leading-[46px]">
        How it works
      </h3>
      <LandingParagraph>{program.howItWorks}</LandingParagraph>

      <div className="relative mx-auto mt-10 grid max-w-[544px] grid-flow-row-dense grid-cols-3 grid-rows-2 gap-[10px]">
        <div className="row-span-2">
          <div className="relative mt-4 h-[108px] w-full overflow-hidden rounded-md sm:mt-6 sm:h-[180px]">
            {program.howItWorksImages && (
              <Image src={program.howItWorksImages[0]} fill className="object-cover" alt="" />
            )}
          </div>
        </div>
        <div className="relative col-span-2 h-[75px] w-full overflow-hidden rounded-md sm:h-[124px]">
          {program.howItWorksImages && (
            <Image src={program.howItWorksImages[1]} fill className="object-cover" alt="" />
          )}
        </div>
        <div className="relative col-span-2">
          <div className="absolute -left-20 h-[75px] w-full overflow-hidden rounded-md sm:h-[124px]">
            <div className="relative h-full w-full">
              {program.howItWorksImages && (
                <Image src={program.howItWorksImages[2]} fill className="object-cover" alt="" />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto mt-10 max-w-[360px] border border-landing-redDark" />
    </div>

    <div className="flex w-full grid-rows-3 flex-col items-start gap-7 lg:grid lg:grid-cols-3 lg:grid-rows-1 lg:gap-8">
      {program?.about?.map((item, index) => (
        <div key={`item_${index}_${item.title}`} className="h-full rounded bg-black p-8 text-left">
          <p className="mb-4 min-h-[62px] font-stag text-[22px] font-semibold">{item.title}</p>
          {item.text.map((p, i) => (
            <p key={`item_${i}_${p}`} className="mb-4 text-base font-light text-white/80 last:mb-0">
              {p}
            </p>
          ))}
        </div>
      ))}
    </div>

    <div className="mt-0 w-full lg:mt-4">
      <div>
        <Link href={PAGE.OPTIONS_SIGNUP} passHref>
          <div className="mx-auto w-min">
            <Button size="large">Get Started</Button>
          </div>
        </Link>
      </div>
      {/* <div className="mx-auto mt-8 max-w-[538px] text-center text-sm text-white/70">
        * Every workout eases you in with a 5-minute warm up & brings you back to planet earth with
        a wonderful yoga-based Cooldown/ Stretch.
      </div> */}
    </div>
  </section>
);

export default LandingHowItWorks;
