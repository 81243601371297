import axios from 'axios';
import { FieldValues } from 'react-hook-form';

import { unwrap, axiosInstance, noAuthAxiosInstance } from '@/utils/index';
import { ENDPOINTS } from '@/constants/endpoints.const';
import { IContactForm } from '@/types/index';

export type EmailSignupType = {
  username: string;
  email: string;
  password: string | number;
  email_marketing: boolean;
};

type EmailSigninType = {
  email: string;
  password: string | number;
};

export default class Fetch {
  static async forgotPassword(email: string) {
    return noAuthAxiosInstance.post(ENDPOINTS.AUTH.PASSWORD_FORGOT, {
      email: email,
    });
  }

  static async updatePassword(hash: string, password: string) {
    return noAuthAxiosInstance.post(ENDPOINTS.AUTH.PASSWORD_UPDATE, { password, hash });
  }

  static async getProducts(): Promise<any> {
    const result = await axios.get('/api/get-products');

    return result.data;
  }

  static countryCode() {
    return unwrap(axiosInstance.get(ENDPOINTS.COUNTRY_CODE));
  }

  static async getEmailSignup({ username, email, password, email_marketing }: EmailSignupType) {
    return await noAuthAxiosInstance.post(ENDPOINTS.AUTH.EMAIL_SIGNUP, {
      username,
      email,
      password,
      email_marketing,
    });
  }

  static async getEmailSignin({ password, email }: EmailSigninType) {
    return await noAuthAxiosInstance.post(ENDPOINTS.AUTH.EMAIL_SIGNIN, { email, password });
  }

  static async getUserByMagicLinkHash(hash: string) {
    return await noAuthAxiosInstance.post(ENDPOINTS.AUTH.GET_USER_BY_MAGIC_LINK_HASH, {
      hash,
    });
  }

  static async magicLinkLogin(data: { email: string }) {
    return noAuthAxiosInstance.post(ENDPOINTS.AUTH.MAGIC_LINK_LOGIN, data);
  }

  static async getUser() {
    return await axiosInstance.get(ENDPOINTS.USER.PROFILE);
  }

  static async updateUser(data: FieldValues) {
    return axiosInstance.post(ENDPOINTS.USER.SETTINGS, data);
  }

  static async changePassword(old: string, password: string) {
    return noAuthAxiosInstance.post(ENDPOINTS.AUTH.PASSWORD_CHANGE, { old, password });
  }

  static async setApiTimestamp(workout_id: number, progress: number) {
    return axiosInstance.post(ENDPOINTS.WORKOUTS.WORKOUT_PROGRESS, { workout_id, progress });
  }

  static async getClientSecret(price_ids: string[] | undefined) {
    return axiosInstance.post(ENDPOINTS.PURCHASES, { price_ids: price_ids });
  }
  static async getClientSecretV2(price_ids: string[] | undefined) {
    return axiosInstance.post(ENDPOINTS.PURCHASES_V2, { price_ids: price_ids });
  }

  static async getPlaceIP() {
    return axios.get('https://ipapi.co/json/');
  }

  static async subscribeToNews(email: string) {
    return axiosInstance.post(ENDPOINTS.NEWS_SUBSCRIPTION, { email });
  }

  static async getInTouch(data: IContactForm) {
    return axiosInstance.post(ENDPOINTS.GET_IN_TOUCH, data);
  }
}
