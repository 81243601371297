import Link from 'next/link';

import { ILandingProgram } from '@/constants/index';
import { PAGE } from '@/router/routes';

import { LandingParagraph } from '@/components/Landing';
import { Button } from '@/components/shared/Buttons';

const ProgramPrice = ({
  program: { price, priceText, purchaseInfo, discount },
}: {
  program: ILandingProgram;
}) => (
  <section className="program-price-gradient flex flex-col items-center justify-center px-6 py-20 sm:px-20 lg:px-[120px]">
    <div className="relative w-full max-w-[871px] text-center">
      <div className="w-full">
        <h3 className="mb-4 font-stag text-[32px] font-bold leading-[32px] sm:text-[46px] sm:leading-[46px]">
          Price
        </h3>
        {priceText && <LandingParagraph className="mb-6">{priceText}</LandingParagraph>}

        {discount && (
          <span className="rounded-lg bg-[#147D94] px-2 py-1.5 font-stag text-base font-semibold">
            {discount}
          </span>
        )}

        {price && (
          <p className="mb-8 mt-3 font-stag text-[32px] font-bold leading-[32px] sm:text-[46px] sm:leading-[46px]">
            {price}
          </p>
        )}
      </div>
      {purchaseInfo && (
        <div className="mb-2 font-stag text-lg font-medium opacity-80">{purchaseInfo}</div>
      )}
      <div>
        <Link href={PAGE.OPTIONS_SIGNUP} passHref>
          <div className="mx-auto w-min">
            <Button size="medium">Get Started</Button>
          </div>
        </Link>
      </div>
    </div>
  </section>
);

export default ProgramPrice;
