import { Fragment, useMemo } from 'react';

import classNames from 'classnames';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import {
  Control,
  FieldValues,
  useController,
  useFormContext,
  UseFormReturn,
  UseControllerProps,
} from 'react-hook-form';

import { SvgArrow } from '@/components/svg';

interface SelectProps {
  id: string;
  name: string;
  defaultValue?: string;

  rules?: Object;
  label?: string;
  disabled?: boolean;
  control?: Control<FieldValues>;
  options: string[];
}

const Select = (props: SelectProps & UseControllerProps) => {
  const { options, disabled, label, id, defaultValue, name } = props;
  const form: UseFormReturn = useFormContext();

  const {
    formState: { errors },
  } = form;

  const {
    field: { value, onChange },
  } = useController(props);
  const error = errors[name];

  const selectClasses =
    'relative h-12 w-full p-3 text-xs text-landing-dark bg-neutrals-white outline-none border rounded-md transition-all duration-300 placeholder:text-landing-dark/50';

  const inputBorderColors = useMemo(
    () =>
      error
        ? 'focus:border-brand-primary border-brand-primary'
        : 'focus:border-neutrals-white border-neutrals-mid',
    // eslint-disable-next-line
    [!!error]
  );

  return (
    <div className={classNames('mb-4 w-full', disabled && 'opacity-50')}>
      <Listbox value={value} onChange={onChange} defaultValue={defaultValue} disabled={disabled}>
        {({ open }) => (
          <>
            {label && (
              <Listbox.Label htmlFor={id} className="mb-1 text-sm font-normal text-neutrals-white">
                {label}
              </Listbox.Label>
            )}
            <div className="relative">
              <Listbox.Button className={classNames(`${inputBorderColors} ${selectClasses}`)}>
                <span className="mr-4 block truncate text-left text-base">{value}</span>
                <span className="pointer-events-none absolute inset-y-0 right-1 flex items-center">
                  <SvgArrow
                    className={classNames(
                      'dark-icon h-6 w-6 transition-all duration-500',
                      open ? 'rotate-180' : 'rotate-0'
                    )}
                  />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md border border-neutrals-mid/50 bg-neutrals-white py-1 text-base text-landing-dark shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {options.map((option, index) => (
                    <Listbox.Option
                      key={`item_${index}_${option}`}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-6 pr-2 ${
                          active && 'bg-neutrals-mid/50 font-medium'
                        }`
                      }
                      value={option}
                    >
                      {({ selected }) => (
                        <>
                          <span className={`block truncate ${value === option && 'font-bold'}`}>
                            {option}
                          </span>
                          {value === option ? (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-1">
                              <CheckIcon className="h-4 w-4 text-landing-dark" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
            {errors[name] && (
              <div className="mt-2 text-xs text-brand-primary">
                {errors[name]?.message?.toString()}
              </div>
            )}
          </>
        )}
      </Listbox>
    </div>
  );
};

export default Select;
