import { Dispatch, Fragment, SetStateAction } from 'react';
import Image from 'next/image';
import Link from 'next/link';

import ImageSessionOne from '@/assets/landing/Bonus_SelfDefense.png';
import ImageSessionTwo from '@/assets/landing/Bonus_Grappling.png';
import ImageSessionThree from '@/assets/landing/Bonus_Core.png';
import ImageSessionFour from '@/assets/landing/Bonus_PartnerDrills.png';

import { LANDING } from '@/constants/index';
import { PAGE } from '@/router/routes';

import { Button } from '@/components/shared/Buttons';
import {
  LandingHeader,
  LandingParagraph,
  ProgramRushfit,
  ProgramRushfitTwo,
  ProgramStrike,
} from '@/components/Landing';

interface LandingProgrammesProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  setVideoUrl: Dispatch<SetStateAction<string | null>>;
}

const sessions = [
  {
    name: (
      <>
        <p className="whitespace-nowrap">Session 1:</p>
        <p className="whitespace-nowrap">Self Defense</p>
      </>
    ),
    image: ImageSessionOne,
  },
  {
    name: (
      <>
        <p className="whitespace-nowrap">Session 2:</p>
        <p>Grappling</p>
      </>
    ),
    image: ImageSessionTwo,
  },
  {
    name: (
      <>
        <p className="whitespace-nowrap">Session 3:</p>
        <p>Core Strength</p>
      </>
    ),
    image: ImageSessionThree,
  },
  {
    name: (
      <>
        <p className="whitespace-nowrap">Session 4:</p>
        <p className="whitespace-nowrap">Partner Drills</p>
      </>
    ),
    image: ImageSessionFour,
  },
];

const LandingProgrammes = ({ setOpen, setVideoUrl }: LandingProgrammesProps) => {
  const programmes = [
    {
      id: LANDING.SECTION_IDS.RUSHFIT_TWO,
      title: (
        <span className="">
          Rushfit<sup>TM</sup> 2
        </span>
      ),
      subtitle: 'Lift your technique and fighting skills to championship level',
      text: [
        'Building on the fitness workouts in Rushfit™',
        'Try 4 weeks of fundamentals and then push yourself to achieve new limits in the 4-week pro phase',
        '8 week training plan (over 5 hours of content)',
        'Mixed ability levels',
      ],
      href: PAGE.RUSHFIT_TWO,
      videoComponent: <ProgramRushfitTwo setOpen={setOpen} setVideoUrl={setVideoUrl} />,
      videoUrl: null,
    },
    {
      id: LANDING.SECTION_IDS.RUSHFIT,
      title: (
        <span>
          Rushfit<sup>TM</sup>
        </span>
      ),
      subtitle: 'Get lean and strong',
      text: [
        'The sell-out fitness phenomenon now available online',
        'Separate workouts cover strength, endurance, power, balance and core conditioning',
        '10 week training plan (over 6 hours of content)',
        'Mixed ability levels',
      ],
      href: PAGE.RUSHFIT,
      videoComponent: <ProgramRushfit setOpen={setOpen} setVideoUrl={setVideoUrl} />,
      videoUrl: null,
    },

    {
      id: LANDING.SECTION_IDS.STRIKE,
      title: 'Strike',
      subtitle: 'Elevate your fitness and kickboxing skills',
      text: [
        'Every workout features 3 x 12 minute rounds, each consisting of kickboxing, ringcraft and floorwork',
        '12 week training plan (over 10 hours of content)',
        'Mixed ability levels',
      ],
      href: PAGE.STRIKE,
      videoComponent: <ProgramStrike setOpen={setOpen} setVideoUrl={setVideoUrl} />,
      videoUrl: null,
    },
  ];

  return (
    <Fragment>
      <div className="bg-landing-dark">
        {programmes.map((item) => (
          <section
            id={item.id}
            key={item.id}
            className="flex flex-col gap-12 px-6 py-20 pb-0 last:pb-20 md:flex-row md:items-center md:gap-20 md:px-20 md:py-[120px] md:odd:flex-row-reverse lg:gap-[100px] lg:px-[120px]"
          >
            <div className="w-full shrink">
              <LandingHeader className="mb-4">{item.title}</LandingHeader>
              <LandingParagraph className="mb-8 font-extrabold">{item.subtitle}</LandingParagraph>
              {item.text.map((paragraph, index) => (
                <div key={`item_${paragraph}_${index}`} className="flex">
                  <div className="mx-3 mt-2 h-2 w-2 shrink-0 rounded-full bg-white" />
                  <LandingParagraph className="mb-2">{paragraph}</LandingParagraph>
                </div>
              ))}
              <div className="mt-10">
                <Link href={item.href} passHref>
                  <div className="w-min">
                    <Button size="large">More info</Button>
                  </div>
                </Link>
              </div>
            </div>
            <div className="w-full max-w-full md:max-w-[484px] lg:max-w-[643px]">
              {item.videoComponent}
            </div>
          </section>
        ))}
      </div>
      <section
        id={LANDING.SECTION_IDS.BONUS_SESSION}
        className="px-6 py-20 text-center md:px-20 lg:px-[120px] lg:py-[120px]"
      >
        <LandingHeader className="mb-4 md:mb-8">Bonus Sessions</LandingHeader>
        <LandingParagraph className="mx-auto mb-8 max-w-[682px] sm:mb-12">
          To help take your MMA journey to the next level, you can also get access to 4 BONUS
          sessions that will provide self-defense tactics, grappling tips, partner drills and core
          strength to genuinely accelerate your technique and gains.
        </LandingParagraph>
        <div className="grid grid-cols-2 items-center justify-center gap-6 lg:grid-cols-4 lg:gap-[86px]">
          {sessions.map((item, index) => (
            <div
              key={`item_${item}_${index}`}
              className="flex flex-col items-center justify-center text-center"
            >
              <div className="mb-6 h-[144px] w-[144px] sm:h-[194px]  sm:w-[194px]">
                <div className="relative flex h-[144px] w-[144px] justify-center overflow-hidden rounded-full sm:h-[194px] sm:w-[194px]">
                  <Image
                    src={item.image}
                    fill
                    className="h-[144px] w-[144px] object-cover sm:h-[194px] sm:w-[194px]"
                    quality={100}
                    alt=""
                  />
                </div>
              </div>
              <div className="font-stag text-base font-semibold leading-4 md:text-[22px] md:leading-6">
                {item.name}
              </div>
            </div>
          ))}
        </div>
        <div className="mt-8 flex justify-center md:mt-14">
          <Link href={PAGE.BONUS_SESSIONS} passHref>
            <div className="w-min">
              <Button size="large">More info</Button>
            </div>
          </Link>
        </div>
      </section>
    </Fragment>
  );
};

export default LandingProgrammes;
