import { useState } from 'react';
import { useForm, SubmitHandler, FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';
import { DotPulse } from '@uiball/loaders';

import { notificationToaster } from '@/utils/index';
import { yupResolver } from '@hookform/resolvers/yup';
import { emailSchema } from '@/validation';
import { ToasterType } from '@/types/components/toaster.type';

const LandingEmailInput = () => {
  const [loading, setLoading] = useState(false);

  const form: UseFormReturn = useForm({
    resolver: yupResolver(emailSchema),
  });
  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = form;

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    setLoading(true);

    try {
      // TODO - replace setTimeout with API call
      setTimeout(() => {
        setLoading(false);
        reset();
        notificationToaster(ToasterType.SUCCESS, 'Thank you for joining Rushfit community');
      }, 2000);
    } catch (error) {
      setLoading(false);
    }
  };

  const classes =
    'email-input block h-10 w-full rounded border border-[#595A59]/25 bg-neutrals-white pr-[120px] sm:pr-36 pl-3 text-xs sm:text-sm leading-6 text-landing-dark outline-none ring-0 focus:border-[#595A59]/25 focus:outline-none focus:ring-0';

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} className="min-h-[70px]">
        <div className="relative mt-1 overflow-hidden rounded">
          <input
            type="email"
            {...register('email')}
            id="email"
            className={classes}
            placeholder="Sign up with email for exclusive news"
            defaultValue=""
          />

          <button
            className="absolute inset-y-0 right-0 flex h-10 cursor-pointer items-center"
            type="submit"
          >
            <span className="flex h-10 w-[110px] items-center justify-center bg-brand-primary font-stag text-base sm:w-[130px]">
              {loading ? <DotPulse size={20} speed={1.3} color="white" /> : 'Join the Rush'}
            </span>
          </button>
        </div>
        {errors.email && (
          <p className="mt-2 text-xs text-landing-redMedium">{errors.email?.message?.toString()}</p>
        )}
      </form>
    </FormProvider>
  );
};

export default LandingEmailInput;
